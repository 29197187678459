<script setup lang="ts">
import { useThemeStore } from "~/stores/theme";
import { computed } from "vue";
import { useRoute } from "vue-router";

const themeStore = useThemeStore();
const themeColor = computed(() => themeStore.themeColor);
</script>

<template>
    <div id="app-container" :class="themeColor">
        <div
            class="relative z-10 flex flex-col justify-between min-h-screen overflow-hidden transition-colors duration-150 bg-site dark:bg-site-dark"
        >
            <SiteHeader/>

            <main class="flex-grow">
                <div class="container space-y-16 text-black dark:text-white text-center">
                    <pre  class="code-block mt-8">
Status Code: <span class="text-red-500">404</span>
showError("<span class="text-red-500">Page not found</span>")
</pre>
                </div>
            </main>

            <SiteFooter/>
        </div>

    </div>
</template>

<style>

</style>